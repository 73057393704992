<template>
  <div class="topBar">
 
  </div>
</template>

<script>
  export default {
    name:'TopBar'
    
  }
</script>

<style lang="scss" scoped>
.topBar{
  width:100%;
  height: 70px;
  border-bottom: 1px solid #dddddd;
  // background-color: #2e3743;
}
</style>